import "../scss/uikit/main.scss";

import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
UIkit.use(Icons);

import Vue from "vue/dist/vue.esm.js";

import VCalendar from "v-calendar";
import "v-calendar/lib/v-calendar.min.css";
Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  popoverVisibility: "focus",
});

Vue.filter("currency", function (value) {
  if (!value) return "";

  value = parseInt(value);
  return Math.floor(value);
});

import AddToCart from "./components/AddToCart.vue";
import RemoveFromCart from "./components/RemoveFromCart.vue";
import GlobalCart from "./components/GlobalCart.vue";
import CheckoutShipping from "./components/CheckoutShipping.vue";
import CartButton from "./components/CartButton.vue";
import SearchButton from "./components/SearchButton.vue";
import NavCloseButton from "./components/NavCloseButton.vue";
import DeliveryDate from "./components/DeliveryDate.vue";
import DeliveryCheck from "./components/DeliveryCheck.vue";
import OrderProcessing from "./components/OrderProcessing.vue";
import SearchButtonMobile from "./components/SearchButtonMobile.vue";
import ServiceLink from "./components/ServiceLink.vue";

new Vue({
  el: "#app",
  components: {
    AddToCart,
    GlobalCart,
    CheckoutShipping,
    RemoveFromCart,
    CartButton,
    SearchButton,
    NavCloseButton,
    DeliveryDate,
    DeliveryCheck,
    OrderProcessing,
    SearchButtonMobile,
    ServiceLink,
  },
});
